<script lang="ts" setup>
const { t } = useI18n();

const COMMENTARIES = Array.from({ length: 3 }, (_, i) => ({
  text: t(`commentaries.${i}.text`),
  name: t(`commentaries.${i}.name`),
  alt: t(`commentaries.${i}.alt`),
}));
const PARAMETERS = [
  {
    animation: {},
    src: '/images/pictures/female-avatar-1.svg',
  },
  {
    animation: { delay: 150, noDelayOnMobile: 992 },
    src: '/images/pictures/female-avatar-2.svg',
  },
  {
    animation: { delay: 300, noDelayOnMobile: 992 },
    src: '/images/pictures/male-avatar-1.svg',
  },
];
</script>

<template>
  <div
    class="commentariesSection text-center px-2 sm:px-4 py-8 lg:px-6 lg:px-8"
  >
    <h2
      v-show-on-visible
      class="text-2xl md:text-5xl text-800 text-center mt-0 mb-4"
    >
      {{ t('title') }}
    </h2>
    <div
      class="commentariesSection__content flex flex-column lg:flex-row align-items-start gap-7 lg:gap-3 xl:gap-4 mx-auto md:px-4 lg:px-2 xl:px-5 pt-8"
    >
      <div
        v-for="(commentary, index) in COMMENTARIES"
        :key="commentary.name"
        v-show-on-visible="PARAMETERS[index].animation"
        class="shadow-2 p-card neon-border relative flex-1"
      >
        <div
          class="absolute left-50 z-3 neon-border"
          style="margin-left: -50px; top: -50px; border-radius: 50%"
        >
          <img
            :src="PARAMETERS[index].src"
            :alt="commentary.alt"
            width="100"
            height="100"
            class="block"
          />
        </div>
        <div class="px-3 sm:px-4 pb-4 pt-8 relative">
          <p
            class="text-900 font-italic line-height-3 text-sm m-0 pb-4 border-bottom-1 surface-border"
          >
            “{{ commentary.text }}”
          </p>
          <div class="text-900 font-semibold line-height-3 mt-4">
            {{ commentary.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="~/locales/commentaries.yaml"></i18n>
<i18n>
en:
  title: Reviews about us

ru:
  title: Отзывы о нас
</i18n>

<style lang="scss">
.commentariesSection {
  background-color: var(--p-surface-700);

  &__content {
    max-width: 1600px;
  }
}
</style>
