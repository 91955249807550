<script lang="ts" setup>
const { t } = useI18n();
</script>

<template>
  <section
    class="productsSection px-2 sm:px-4 py-8 md:px-6 lg:px-8"
    id="products"
  >
    <NuxtImg
      class="page-image-preview none md:block"
      src="/images/products-background.jpg"
      width="1700"
      height="1133"
      sizes="lg:100vw xl:100vw xxl:100vw"
      :alt="t('preview_alt')"
      format="webp"
      loading="lazy"
    />
    <div
      class="page-overflow-7 section-top-shadow section-bottom-shadow none md:block"
    />

    <h2
      v-show-on-visible
      class="text-2xl md:text-5xl text-800 text-center mb-4 mt-4 relative z-1"
    >
      {{ t('title') }}
    </h2>
    <div class="grid z-2 relative">
      <div class="col-12 md:col-6 p-2 sm:p-3 xl:p-6">
        <div
          v-show-on-visible
          class="p-card mb-3 h-full flex-column justify-content-between flex neon-border"
        >
          <div class="p-4">
            <div class="flex align-items-start">
              <span
                class="inline-flex border-circle align-items-center justify-content-center mr-3"
                style="
                  flex: 0 0 38px;
                  width: 38px;
                  height: 38px;
                  background-color: var(--p-teal-600);
                "
              >
                <i class="pi pi-heart text-xl text-green-600" />
              </span>
              <h3 class="text-900 font-medium text-lg md:text-2xl my-0">
                {{ t('tasks_subtitle') }}
              </h3>
            </div>
            <p
              class="text-900 mt-4 mb-2 text-sm md:text-lg font-medium line-height-3"
            >
              {{ t('tasks_description') }}
              <br />
              <br />
              {{ t('tasks_rules') }}
            </p>
          </div>
          <div
            class="px-4 py-3 surface-100 productsSection__cardFooter flex flex-wrap gap-3 justify-content-end"
          >
            <NuxtLink to="/about-fants">
              <Button rounded outlined size="large" type="button">
                {{ t('find_out_more') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/game/settings">
              <Button rounded size="large" type="button">
                {{ t('start_the_game') }}
              </Button>
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 p-2 sm:p-3 xl:p-6">
        <div
          v-show-on-visible="{ delay: 150, noDelayOnMobile: 768 }"
          class="p-card mb-3 h-full flex-column justify-content-between flex neon-border"
        >
          <div class="p-4">
            <div class="flex align-items-start">
              <span
                class="inline-flex border-circle align-items-center justify-content-center mr-3"
                style="
                  flex: 0 0 38px;
                  width: 38px;
                  height: 38px;
                  background-color: var(--p-purple-600);
                "
              >
                <i class="pi pi-question text-xl text-purple-600" />
              </span>
              <h3 class="text-900 font-medium text-lg md:text-2xl my-0">
                {{ t('interests_subtitle') }}
              </h3>
            </div>
            <div
              class="text-900 mt-4 mb-2 text-sm md:text-lg font-medium line-height-3"
            >
              <p class="m-0">
                {{ t('interests_description') }}
              </p>
              <ul class="my-2 pl-3">
                <li>{{ t('interests_steps.0') }}</li>
                <li>{{ t('interests_steps.1') }}</li>
                <li>{{ t('interests_steps.2') }}</li>
                <li>{{ t('interests_steps.3') }}</li>
              </ul>
            </div>
          </div>
          <div
            class="px-4 py-3 surface-100 productsSection__cardFooter flex flex-wrap gap-3 justify-content-end"
          >
            <NuxtLink to="/about-fants#interests">
              <Button rounded outlined size="large" type="button">
                {{ t('find_out_more') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/interests">
              <Button rounded size="large" type="button">
                {{ t('go_to_questions') }}
              </Button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
en:
  title: What you will find on our website
  preview_alt: Uncover new interests of your partner
  tasks_subtitle: Erotic tasks for a couple
  tasks_description: Erotic tasks for a couple - a game designed for those who want to bring spark and passion into their
    sex life, try something new in bed, or just diversify their intimate life.
  tasks_rules: 'The essence of the game is simple: in turn, each opens a card and completes the task. It is important that
    at this moment your partner does not see the tasks, so the game will be more interesting, and the sensations will be
    brighter!'
  interests_subtitle: Uncover new interests of your partner
  interests_description: 'We will help you find new hot ideas for sex. How it works:'
  interests_steps:
    - Both partners connect their accounts;
    - Both partners answer the same sex questions «Yes», «Maybe», «No»;
    - If both partners answered «Yes» or «Maybe», then the question is shown as a match;
    - Any idea that is answered «No» by any of the partners will be hidden.
  find_out_more: More
  start_the_game: Start the game
  go_to_questions: Go to questions

ru:
  title: Что вы у нас найдете
  preview_alt: Раскрыть новые интересы партнера
  tasks_subtitle: Эротические фанты и задания
  tasks_description: Игра в эротические фанты — для желающих внести огонек и страсть в свою сексуальную жизнь, опробовать
    что-то новое в постели, либо просто разнообразить интимную жизнь.
  tasks_rules: 'Суть игры проста: поочередно каждый открывает фант и выполняет задание. Важно, чтобы в этот момент Ваша
    половинка не видела задания, так игра будет интересней, а ощущения ярче!'
  interests_subtitle: Раскрыть новые интересы партнера
  interests_description: 'Поможем найти новые горячие идеи для секса. Как это работает:'
  interests_steps:
    - Оба партнера соединяют свои аккаунты;
    - Оба партнера отвечают одинаковые секс вопросы «Да», «Может», «Нет»;
    - Если оба партнера ответили «Да» или «Может», то вопрос показан как совпадение;
    - Любая идея на которую ответил «Нет» любой из партнеров, будет спрятана.
  find_out_more: Подробнее
  start_the_game: Начать игру
  go_to_questions: К вопросам
</i18n>

<style lang="scss">
.productsSection {
  position: relative;
  background-color: black;

  .grid {
    max-width: 1600px;
    margin: 0 auto;
  }

  &__cardFooter {
    border-radius: 16px !important;
  }
}
</style>
